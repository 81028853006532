
import React from 'react';
import computer from './assests/No27.png';
import backgroundImage from './assests/demo18.png';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Demo() {
return(
<div className="mt-10 bg-[#ffffff] flex flex-col lg:flex-row">
  <div className="lg:w-1/2 p-5 lg:p-10">
    <div className="w-full text-start font-[Poppins] max-w-screen-lg text-[#333333] mt-10">
      <h4 className="font-bold text-[20px]">Demo Project (s) </h4>
      <p className="mt-10 font-bold text-[20px] sm:text-[16px] md:text-[20px] text-start">
  Experience seamless health data integration from multiple <br />
  EHR systems into a single, user-friendly interface.<br />
  Our solution uses FHIR standards for interoperability<br />
  and ensures HIPAA-compliant security for patient data.
</p>

<ul className="mt-5 font-semibold text-[16px] sm:text-[10px] md:text-[12px] list-disc pl-5 space-y-2">
  <div className="flex items-center space-x-2">
  <svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 -960 960 960" width="12px" fill="#03BB7D">
    <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
  </svg>
  <span>FHIR Standards: Easy data exchange between EHRs.</span>
</div>
<div className="flex items-center space-x-2">
<svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 -960 960 960" width="12px" fill="#03BB7D"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
<span>HIPAA Compliance: Secure and private patient data.</span>
</div>
<div className="flex items-center space-x-2">
<svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 -960 960 960" width="12px" fill="#03BB7D"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
<span> Real-Time Sync: Up-to-date information across systems.</span>
  </div>
  <div className="flex items-center space-x-2">
  <svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 -960 960 960" width="12px" fill="#03BB7D"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
  <span> Centralized Dashboard: Manage all data from one place.</span>
  </div>
</ul>
<div className="flex justify-center sm:justify-center md:justify-end mt-6">
  <a
    href="http://ehr-ens.s3-website-us-east-1.amazonaws.com/"
    target="_new"
    className="blinking-button rounded-full bg-[#03BB7D] text-white font-[Poppins] text-sm md:text-base lg:text-lg flex justify-center items-center"
    style={{ width: '8rem', height: '2.5rem' }}
  >
    Demo
    {/* <svg xmlns="http://www.w3.org/2000/svg" className='mt-1 ml-2' height="12px" viewBox="http://www.w3.org/2000/svg" width="24px" fill="#ffffff">
      <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/>
    </svg> */}
  </a>
</div>
    </div>
  </div>

  <div className="lg:w-1/2 mt-4 lg:mt-0 p-5 self-center lg:self-start">
    <img
      className="w-full h-auto lg:block"
      src={computer}
      alt="Computer illustration"
      style={{ maxHeight: '30rem' }}
    />
  </div>
</div>

)
}
export default Demo;