import Jd from "./Frame1";


function App() {
  return (
    <div className="App">
      <Jd/>
    </div>
  );
}

export default App;