import React from 'react';
import './Spamie.css'; // Import your custom styles here
import vector1 from './assests/Vector (1).png';
import member from './assests/Group.png';
import icon1 from './assests/noun-computer-6433585 1.png';
import icon2 from './assests/noun-independent-work-6605861 1.png';
import icon3 from './assests/noun-insurance-6696856 2.png';

const PointerIcon = ({ fill, stroke, margin, year, arrow, arrow1, direction, text, position, y1, y2, direction1, position1, y11, y21 }) => {
  const circleRadius = 12;
  const lineHeight = 30;
  const svgHeight = 100;
  const extraCircleRadius = 18; // Adjust this radius as needed

  return (
    <div className='w-full xl:mt-12 md:w-20 sm:mr-0'>
      <svg className='sm:w-16 sm:h-96 md:w-30 svg-container'
        viewBox={`0 0 100 ${svgHeight}`} 
        xmlns="http://www.w3.org/2000/svg" 
        style={{ height: `200px`, marginTop: margin }}
      >
        <path 
          d={`M50 0
            C70 0 90 20 90 40
            C90 60 70 80 50 100
            C30 80 10 60 10 40
            C10 20 30 0 50 0
            Z
            M50 ${lineHeight}
            C37 ${lineHeight} 25 27 25 40
            C25 53 37 65 50 65
            C63 65 75 53 75 40
            C75 27 63 ${lineHeight} 50 ${lineHeight}
            Z`}
          fill={fill} 
          stroke={stroke} 
          strokeWidth="2"
        />
        <circle cx="50" cy="40" r="29" fill="white" />
        <text x="50" y="45" fontSize="13" textAnchor="middle" fill="black" fontWeight="bold">{year} </text>
        <line 
          x1="50" 
          y1={`${svgHeight}`} 
          x2="50" 
          y2={`${svgHeight + lineHeight}`} 
          stroke={stroke} 
          strokeWidth="2" 
          strokeDasharray="4,4" 
        />
        <circle cx="50" cy={svgHeight + lineHeight + circleRadius} r={circleRadius} fill={fill} stroke={stroke} strokeWidth="1" />

        <circle cx="50" cy={svgHeight + lineHeight + circleRadius} r={extraCircleRadius} fill="none" stroke={stroke} strokeWidth="2" />
      
        {arrow && (
          <>
            <line 
              x1={position} 
              y1={y1} 
              x2={direction} 
              y2={y2} 
              stroke={stroke} 
              strokeWidth="5" 
              markerEnd="url(#arrowhead)"
            />
            <defs>
              <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill={stroke} />
              </marker>
            </defs>
          </>
        )}
        {arrow1 && (
          <>
            <line 
              x1={position1} 
              y1={y11} 
              x2={direction1} 
              y2={y21} 
              stroke={stroke} 
              strokeWidth="5" 
              markerEnd="url(#arrowhead)"
            />
            <defs>
              <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill={stroke} />
              </marker>
            </defs>
          </>
        )}
      </svg>
      <div className="text-center  font-bold" style={{ fontSize: '0.9rem', fontFamily: 'Poppins', }}>{text}</div>
    </div>
  );
}

function Frame2() {
  return (
    
    <div className="w-full rounded-sm bg-white shadow-default dark:border-strokedark p-6  ">

<div className="flex flex-col md:flex-row items-center px-4 md:px-8  ">

  <div className="w-full md:w-1/2  md:mb-0">
    <div className="header-content">
      <div className="mb-6 text-start font-[Poppins] font-semibold text-xl tracking-wide text-teal-600">
        About Us
      </div>
      <div className="mb-4 text-start font-[Poppins] font-semibold text-xl tracking-wide text-gray-800">
        A Company is driven by employee satisfaction and customer success.
      </div>
      <div className="mb-4 text-start font-[Poppins] font-normal text-base leading-relaxed text-gray-700">
        At Elon Native System, we are pioneers in leveraging IT technology to transform the healthcare landscape. With a profound understanding of both healthcare and technology, we are committed to empowering healthcare providers and enhancing patient care.
      </div>
      <div className="flex flex-wrap">
        <div className="experience-item flex items-center mb-4 mr-8">
          <img src={member} alt="Members" className="w-12 h-12 mr-2" />
          <div className="experience-text text-start">
            <span className="text-lg font-bold font-[Poppins]">4+</span>&nbsp;
            <span className="text-sm font-[Poppins] font-normal">Years of Experience</span>
          </div>
        </div>
        <div className="experience-item flex items-center mb-4">
          <img src={vector1} alt="Experience" className="w-12 h-12 mr-2" />
          <div className="experience-text text-start">
            <span className="text-lg font-bold font-[Poppins]">45+</span>&nbsp;
            <span className="text-sm font-[Poppins] font-normal">Team Members</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Pointer Icons Section */}
  <div className="w-full md:w-1/2 flex flex justify-center  mt-0 sm:h-96 mb-10">
    <PointerIcon 
      fill="#01C181" 
      stroke="#01C181" 
      margin="40" 
      year="2021 Jan"
      arrow={true} 
      direction='350'
      position='50'
      y1='140'
      y2='-20'
      text='3 long term Project 6 members' 
      className=''
    />
    <PointerIcon 
      fill="#00A9BC" 
      stroke="#00A9BC" 
      margin="1px" 
      year="2022"
      arrow1={true} 
      direction1='550'
      position1='50'
      y11='140'
      y21='-150'
      arrow={true} 
      direction='-650'
      position='40'
      y1='140'
      y2='520'
      text='5 long term Project 20 members'
    />
    <PointerIcon 
      fill="#006683" 
      stroke="#006683" 
      margin="-44" 
      year="2023"
      arrow={true} 
      direction='-990'
      position='50'
      y1='140'
      y2='700'
      arrow1={true} 
      direction1='350'
      position1='60'
      y11='140'
      y21='-15'
      text='6 long term Project 20 members'
    />
    <PointerIcon 
      fill="#FF4059" 
      stroke="#FF4059" 
      margin="-80px" 
      year="2024"
      arrow1={true} 
      direction1='-890'
      position1='50'
      y11='140'
      y21='580'
      text='8 long term Project 45+ members'
    />
  </div>
</div>

      {/* Services */}
    
<div className="flex flex-col lg:flex-row gap-4 lg:gap-8 w-full lg:w-auto xl:p-8">
  {/* First Column */}
  <div className="flex flex-col items-center w-full lg:w-1/3 box-border">
    <div className="border_bruh bg-white relative flex flex-col p-4 lg:p-6 w-full h-full box-border">
      <div className=" lg:m-4 flex self-center w-12 h-12 lg:w-16 lg:h-16">
        <img src={icon1} className="w-full h-full object-contain" alt="Icon 1" />
      </div>
      <div className="m-2 lg:m-4 text-center  text-lg lg:text-xl font-[Poppins]  tracking-wide text-black">
        Healthcare Software Companies
      </div>
      <span className="m-2 lg:m-4 text-center  text-sm lg:text-base font-[Poppins]  leading-normal lg:leading-relaxed text-black">
        Choose Elon Native Systems to swiftly assemble high-value software teams, accelerating your healthcare software to market ahead of competitors.
      </span>
    </div>
    <div className="bg-green-600 w-full lg:w-full h-2.5 lg:h-2 box-border"></div>
  </div>
  
  {/* Second Column */}
  <div className="flex flex-col items-center w-full lg:w-1/3 box-border">
    <div className="border_bruh relative flex flex-col p-4 lg:p-6 w-full h-full box-border">
      <div className="relative m-2 lg:m-4 flex self-center w-12 h-12 lg:w-16  lg:h-16">
        <img src={icon2} className="w-full h-full object-contain" alt="Icon 2" />
      </div>
      <div className="m-2 lg:m-4 text-center  text-lg lg:text-xl font-[Poppins] tracking-wide text-black">
        Investing in the Healthcare
      </div>
      <span className="m-2 lg:m-4 text-center text-sm lg:text-base font-[Poppins]  leading-normal lg:leading-relaxed text-black">
        Partner with Elon Native Systems to evaluate the viability of software platforms before funding decisions, enhancing the value of their portfolio companies.
      </span>
    </div>
    <div className="bg-green-600 w-full lg:w-full h-2.5 lg:h-2 box-border"></div>
  </div>
  
  {/* Third Column */}
  <div className="flex flex-col items-center w-full lg:w-1/3 box-border">
    <div className="border_bruh bg-gray-100 relative flex flex-col p-4 lg:p-6 w-full h-full box-border">
      <div className="m-2 lg:m-4 flex self-center w-12 h-12 lg:w-16 lg:h-16">
        <img src={icon3} className="w-full h-full object-contain" alt="Icon 3" />
      </div>
      <div className="m-2 lg:m-4 text-center  text-lg lg:text-xl font-[Poppins] tracking-wide text-black">
        Healthcare Providers And Payers
      </div>
      <span className="m-2 lg:m-4 text-center  text-sm lg:text-base font-[Poppins]  leading-normal lg:leading-relaxed text-black">
        Collaborate with Elon Native Systems to develop technology that improves the lives of patients, members, and staff.
      </span>
    </div>
    <div className="bg-green-600 w-full lg:w-full h-2.5 lg:h-2 box-border"></div>
  </div>
</div>


    </div>
  );
}

export default Frame2;



