import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import computer from './assests/hire1.jpg';
import Modal from 'react-modal';
import { FaFilePdf } from "react-icons/fa6";
function Career() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    setLoading(true);

    // Create a FormData object to append the file
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await emailjs.send(
        'service_t2q4xf8', 
        'template_zjefl1q',
        formData,
        'PtpjS-cfP143RjBnl'
      );
      console.log('Email sent successfully:', response.text);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error.text);
      alert('Failed to send email.');
    } finally {
      setLoading(false);
     
    }
  };

  return (
    <div className="w-full bg-[#f4ede3]">
      <div className="flex flex-col lg:flex-row justify-around ">
        <div className="lg:w-1/2 flex flex-col p-5 lg:p-10">
          <div className="font-[Poppins] mb-5   mx-5 self-start break-words font-semibold text-xl tracking-wide leading-loose text-green-600">
            Career
          </div>
          <div>
            <div className="text-start lg:m-0 p-4 w-full font-[Poppins] sm:w-full max-w-screen-md">
              <h2 className="font-bold text-[22px]">Work with us</h2>
              <p className="mt-6  font-bold text-[15px]">Explore flexible opportunities and join us in the</p>
              <p className="mt-0 font-bold text-[15px]">office to be a part of our mission to make work life simpler,</p>
              <p className="mt-0 font-bold text-[15px]">more pleasant, and more productive.</p>
            </div>

            <div className="flex flex-col lg:flex-row font-[Poppins] items-start xl:mt-4 mx-4 self-start">
  Send your resume to &nbsp;
  <a
    href="mailto:admin@elonnativesystem.com"
    className="text-blue-600"
  >
    admin@elonnativesystem.com
  </a>
</div>


          </div>
        </div>
        <div className="lg:w-1/2 mt-10 p-5 bg-no-repeat self-start h-full rounded-lg overflow-hidden">
          <img className="w-full h-auto" src={computer} alt="animate" />
        </div>
      </div>
    </div>
  );
}

export default Career;
