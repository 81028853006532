import React, { useState } from 'react';
import Men from './assests/male5.png';
import Men1 from './assests/male1.png';
import Men2 from './assests/male2.png';
import Men3 from './assests/male3.png';
import Men4 from './assests/male4.png';
import Kartheeswari from './assests/Kartheeswari.png';
import women from './assests/female1.png';
import female1 from './assests/female2.png';
import G from './assests/Santhanam.png';
import Gurunathan from './assests/Gurunathan 1.png';
import Muthumani from './assests/Muthumani.png';
import Govindaraj from './assests/Govind.png';
import Mohana from './assests/Mohana.png';
import Ponnuthai from './assests/Ponnuthai.png';
import Ganeshkumar from './assests/GaneshKumar.png';
import Pandiyarajan from './assests/Pandiyaraj.png';
import Elavarasan from './assests/Elavarasan.png';
import Subitha from './assests/Subitha.png';
import Manikandan from './assests/Manikandan.png';
import Kanagavel from './assests/kanagavel1.png';
import Ramadevi from './assests/Ramadevi.png';
import Lakshmanan from './assests/laks1.png';
import Shankar from './assests/Shankar.png';
import Sathish from './assests/Sathish.png';
import RamKumar from './assests/ramkumar1.png';
import Vishnu from './assests/Vishnu.png';
import RamSubu from './assests/Ramsubu.png';
import RajkumarP from './assests/Rajkumar P.png';
import RajkumarS from './assests/Rajkumar S.png';
import Rajeswari from './assests/Rajeshwari.png';
import Meenalakshmi from './assests/Meenalakshmi.png';
import Ajith from './assests/ajith.png';
import Thiruvel from './assests/Thiruvel.png';
function Teams() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="bg-[#FFFFFF]   flex flex-col">
      <div className="m-[0__5px_50px]  mx-10 mt-10 inline-block self-start break-words font-['Poppins'] font-semibold text-[20px] tracking-[0.5px] text-[#1A9A6F]">
        Our Team
      </div>
      <div className="m-4 lg:m-[0_78.9px_36px_78.9px] inline-block self-start break-words font-['Poppins'] font-semibold text-[21px] tracking-[0.5px] text-[#000000]">
        Leadership @ Elon Native Systems
      </div>

      {/* Team Members */}
      <div className="flex flex-col items-center self-center w-full lg:w-[fit-content] box-border">

        {/* Team Member 1 */}
        <div className="rounded-lg overflow-hidden">
          <div className="text-center py-6">
            <img className="mx-auto h-28 md:h-32 lg:h-20" src={Men3} alt="Rajaguru" />
          </div>
          <div className="text-center ">
            <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Rajaguru N</p>
            <span className="break-words font-['Poppins'] font-normal text-[12px] tracking-[0.5px] text-[#000000]">
              Advisory
            </span>
          </div>
        </div>

        {/* Team Member 2 */}
        <div className="m-4 lg:m-[0_12.3px_32px_0] flex flex-col lg:flex-row self-center w-full lg:w-[fit-content] lg:grid-cols-2 xl:grid-cols-2 gap-20  box-border">
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Kartheeswari} alt="Kaartheeswari" />
            </div>
            <div className="text-center">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Kartheeswari Paramasivam</p>
            </div>
            <span className="break-words font-['Poppins'] text-[12px] tracking-[0.5px] text-[#000000]">
              Co-Founder
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={women} alt="Jasmine" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Jasmine M</p>
            </div>
            <span className="m-[0_6.1px_0_10.1px] break-words font-['Poppins'] font-normal text-[12px] tracking-[0.5px] text-[#000000]">
              Co-Founder
            </span>
          </div>
        </div>
        <div className="m-4 lg:m-[0_12.3px_32px_0] flex  lg:flex-row grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4  w-full box-border  ">
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={G} alt="Santhanam" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Santhanam Gurusamy</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              CEO & CFO
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Men2} alt="Janakiraman" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Janakiraman M</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              COO
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Men1} alt="Shiva" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Shiva P</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              CTO
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Men4} alt="Mani" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Mani G</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Delivery Head & Sales
            </span>
          </div>
        </div>
        {/* Team Member 3 */}
        <div className="m-4 lg:m-[0_12.3px_32px_0] flex flex-col lg:flex-row grid grid-cols-1 md:grid-cols-1 lg:grid-cols-6 xl:grid-cols-6 self-center w-full lg:w-[] box-border md:gap-10 xl:gap-20">

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Men1} alt="Samy K" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Samy K</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Technical Lead<br />
              Interoperability
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Gurunathan} alt="Gurunathan" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Gurunathan S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Technical Lead<br />
              Full Stack
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center ">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Muthumani} alt="Muthumani" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Muthumani S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Technical Lead<br />
              Full Stack
            </span>
          </div>

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Govindaraj} alt="Govindaraj" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Govindaraj P</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Technical Lead<br />
              Web and Mobile Frontend
            </span>
          </div>

          {/*Shankar  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Shankar} alt="Shankar" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Shankar K</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Senior Software Engineer
            </span>
          </div>
          {/*Pandiyarajan  */}

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Pandiyarajan} alt="Pandiyarajan" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Pandiyarajan G</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Senior Software Engineer
            </span>
          </div>
        </div>
        <div className=" m-4 lg:m-[0_12.3px_32px_0]flex flex-row items-center my-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-6 xl:grid-cols-6 self-center w-full box-border md:gap-10 xl:gap-10">
          
          {/*Ponnuthai  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Ponnuthai} alt="Ponnuthai" />
            </div>
            <div className="text-center">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Ponnuthai R</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
          {/*Rajeswari */}

          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Rajeswari} alt="Rajeshwari" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Rajarajeswari S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>

          {/*rajkumar s  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={RajkumarS} alt="RajkumarS" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Rajkumar S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
 {/*rajkumar P  */}
 <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6 ">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={RajkumarP} alt="RajkumarP" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Raj Kumar P</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
          {/*Manikandan  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6 ">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Manikandan} alt="Manikandan" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Manikandan p </p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>

                 {/*mohana priya  */}
                 <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Mohana} alt="Mohana" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Mohana Priya</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              HR Administration
            </span>
          </div>
         
        </div>


        <div className="flex flex-col items-center my-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-6 xl:grid-cols-6 self-center w-full box-border ">

   
          {/*Ramadevi  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6 ">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Ramadevi} alt="Ramadevi" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Ramadevi P</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>

          {/*Subitha  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Subitha} alt="Subitha" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Subitha V</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>


          {/*kanagavel  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6 ">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Kanagavel} alt="Kanagavel" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Kanagavel B</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
       

       
        {/*Elavarasan  */}
        <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6 ">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Elavarasan} alt="Elavarasan" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Elavarasan S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>


          {/*Lakshmanan  */}


          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Lakshmanan} alt="Laksmanan" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Sundharalakshmanan S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>



          {/*Sathish  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Sathish} alt="Sathish" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Sathish Kumar S</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
        </div>

        <div className="flex flex-col items-center my-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 self-center w-full box-border md:gap-10 xl:gap-20">
      

   
          {/*GaneshKumar  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Ganeshkumar} alt="Ganeshkumar" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Ganeshkumar B</p>
            </div>
            <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
      {/*Vishnu  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={Vishnu} alt="Vishnu" />
            </div>
            <div className="text-center ">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Vishnuraj S</p>
            </div>
            <span className="break-words font-['Poppins'] text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
      {/*RamKumar  */}
          <div className="rounded-lg overflow-hidden text-center">
            <div className="text-center py-6">
              <img className="mx-auto h-28 md:h-32 lg:h-20" src={RamKumar} alt="Ramkumar" />
            </div>
            <div className="text-center">
              <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Ramkumar R</p>
            </div>
            <span className="m-[0_6.1px_0_10.1px] break-words font-['Poppins'] font-normal text-[12px] tracking-[0.5px] text-[#000000]">
              Software Engineer
            </span>
          </div>
  {/*Ramasubu  */}
  <div className="rounded-lg overflow-hidden text-center">
          <div className="text-center py-6">
            <img className="mx-auto h-28 md:h-32 lg:h-20" src={RamSubu} alt="Ramsubu" />
          </div>
          <div className="text-center ">
            <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Ramasubramaniyan P</p>
          </div>
          <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
            Software Engineer
          </span>
        </div>
        </div>
        </div>

        <div className="m-4 lg:m-[0_12.3px_32px_0] flex flex-col lg:flex-row self-center w-full lg:w-[fit-content] lg:grid-cols-2 xl:grid-cols-2 gap-20 box-border">
           {/*Meenalakshmi */}
  <div className="rounded-lg overflow-hidden text-center">
          <div className="text-center py-6">
            <img className="mx-auto h-28 md:h-32 lg:h-20" src={Meenalakshmi} alt="Meenalakshmi" />
          </div>
          <div className="text-center ">
            <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Meenalakshmi R</p>
          </div>
          <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
            Project Manager
          </span>
        </div>
      {/*Ajith  */}
  <div className="rounded-lg overflow-hidden text-center">
          <div className="text-center py-6">
            <img className="mx-auto h-28 md:h-32 lg:h-20" src={Ajith} alt="Ajith" />
          </div>
          <div className="text-center ">
            <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Ajithkumar S</p>
          </div>
          <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
            Software Engineer
          </span>
        </div>
      </div>
       {/*thiruvel  */}
  <div className="rounded-lg overflow-hidden text-center">
          <div className="text-center py-6">
            <img className="mx-auto h-28 md:h-32 lg:h-20" src={Thiruvel} alt="Thiruvel" />
          </div>
          <div className="text-center ">
            <p className="text-sm md:text-sm lg:text-sm font-['Poppins'] font-bold text-[#000000]">Thiruvelmurugan P</p>
          </div>
          <span className="break-words font-['Poppins'] font-normal text-center text-[12px] tracking-[0.5px] text-[#000000]">
            Software Engineer
          </span>
        </div>
      <div className='bg-gray-100 mt-10 '>
        <div className='text-center mt-10 xl:mt-20 bg-gray-100'>
          <p className='text-4xl font-bold  font-[Poppins] '>Our Alumni</p>
        </div>
        <p className='text-lg text-center xl:mt-5 mx-5  font-[Poppins] '>
          Our relationships with employees last a lifetime. Here are a few of our ex-colleagues who made remarkable
          contributions to our journey.</p>
        <p className='text-lg text-center mt-0  mx-5   font-[Poppins]'>
          We continue to appreciate and respect what they have done for us.</p>

        <div className='flex flex-wrap justify-center gap-4 lg:gap-8 lg:mt-10 xl:mt-12 mb-20'>
          <div className='border bg-gray-400 p-2 w-full max-w-[10rem] lg:w-auto'>
            <p className='text-center'>Udhaya Prakash</p>
          </div>
          <div className='border bg-gray-400 p-2 w-full max-w-[10rem] lg:w-auto'>
            <p className='text-center'>VeeraLakshmi</p>
          </div>
          <div className='border bg-gray-400 p-2 w-full max-w-[10rem] lg:w-auto'>
            <p className='text-center'>Selva Priya</p>
          </div>
          <div className='border bg-gray-400 p-2 w-full max-w-[10rem] lg:w-auto'>
            <p className='text-center'>Santhana Kumar</p>
          </div>

        </div>
      </div>
    </div>

  );
}

export default Teams;




































