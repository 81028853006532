import React from 'react';

function Frame3() {
  return (
    <div className="bg-[#F5FFFC] flex flex-col  w-full  box-border">
      <div className=" p-6  md:p-12 self-start break-words font-['Poppins'] font-semibold text-[20px] tracking-[0.5px] text-[#1A9A6F]">
        Services
      </div>
      <div className=" px-4 md:px-12 break-words self-start font-['Poppins'] font-normal text-[16px] md:text-[16px] tracking-[0.5px] text-[rgba(0,0,0,0.8)]">
        Our Interoperability and Integration services encompass a broad array of solutions,such as
      </div>
      <div className=" p-10 grid grid-cols-1 self-center flex m-8 md:grid-cols-2 lg:grid-cols-3 gap-6 w-auto ">
        <ServiceBox
          title="EHR & Healthcare Systems Integration with AI"
          description="Develop new or enhance existing Electronic Health Record (EHR) connections, leveraging Artificial Intelligence (AI) to seamlessly integrate Electronic Medical Record (EMR) systems, payers, Health Information Exchanges (HIEs)."
        />
        <ServiceBox
          title="Custom API Development & Integrations"
          description="Tailor your EHR data retrieval to fit your organization's specific requirements. Seamlessly integrate and maintain connections, ensuring smooth data sharing with healthcare partners."
        />
        <ServiceBox
          title="SMART on FHIR App Development"
          description="Create custom EHR-integrated applications tailored to your needs. Enhance digital health experiences with advanced EHR functionality using SMART on FHIR."
        />
        <ServiceBox
          title="FHIR Compatibility"
          description="As cloud adoption becomes standard, we offer strategic migration planning and execution to FHIR-compliant solutions for your healthcare business."
        />
        <ServiceBox
          title="HL7 Integration & Configuration Support"
          description="We specialize in configuring HL7 listeners and receivers for bi-directional data exchange, facilitating real-time updates to streamline processes. Our team excels in mapping and transforming data between HL7 versions and formats."
        />
        <ServiceBox
          title="Pre-Built API Library"
          description="Elon Native Systems boasts a library of robust, configurable, pre-tested APIs alongside an HL7 conversion engine, data exchange and integration. We assist in configuring, testing, and implementing connected EHRs."
        />
      </div>
    </div>
  );
}

const ServiceBox = ({ title, description }) => (
  <div className="relative flex flex-col items-start p-4 h-auto w-full md:w-[300px] rounded-[9px] box-border border-2 shadow-xl bg-white transition-transform duration-300 hover:shadow-2xl hover:scale-105">
    <div className="text-start relative mb-4 break-words font-['Inter'] font-bold text-[22px] tracking-[0.5px] leading-[1.636] text-[#262C65]">
      {title}
    </div>
    <span className="relative text-start break-words font-['Inter'] font-normal text-[14px] tracking-[0.5px] leading-[1.636] text-[#262C65]">
      {description}
    </span>
  </div>
);


export default Frame3;




